.qrObsah {
  font-size:28px;
  /* padding-left:25%; */
  font-weight:bold;
  border:1px solid black;
  padding:10px;
  overflow:auto;
  border-radius:10px;
  min-height:200px;
  overflow:auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.centerMe {
  margin: auto;
  width: 60%;
  border: 5px solid #FFFF00;
  padding: 10px;
  }